import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

// import VideoThumbnail from '../../Assets/Images/video-thumbnail.png';
import VideoThumbnail from '../../Assets/Images/video-thumbnail-Esler.png';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
import PlayButton from '../../Assets/Images/play-btn.png';
import PlayButtonHover from '../../Assets/Images/play-btn-hover.svg';
import Clock from '../../Assets/Images/clock.png';
import { Close } from "@mui/icons-material";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { Button, CircularProgress } from '@mui/material';
import Pause from '@mui/icons-material/Pause';


const Start = props => {

  const { windowWidth } = useWindowDimensions();
  const { id, pageId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [playingVideo, setPlayingVideo] = useState(false);
  const [startPlaying, setStartPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);


  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();



  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  const createAssessment = async () => {
    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }
    const clientId = process.env.REACT_APP_CLIENT_ID;
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
        method: 'POST', mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ clientId, isMember, leaderId, userId })
      });
      const data = await response.json();
      if (data.status === "success") {
        // navigate("/explainer/" + data.id + "?" + searchParams, { replace: true });
        console.log("data", data);
      }
      return data;
    } catch (err) {
      console.error("Failed to create new assessment...", err);
      throw err;
    }
  }


  const submitCategoryToApi = async (userId) => {
    const pageId = 892;
    let apiCall = `/v3/user/assessment/${id}/form/${pageId}`;
    const orgType = JSON.parse(localStorage.getItem("SELECTED_CATEGORY"))?.name;
    let jsonBody = JSON.stringify({
      details: { "organizationType": orgType?.trim() },
      noAutomation: true,
      isCompleted: false
    });
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + apiCall, {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: jsonBody
      });
      const data = await response.json();
      if (data.status === "success") {
        navigate("/explainer/" + id + "?" + searchParams, { replace: true });
        localStorage.removeItem("assessmentAnswers");
      } else {
        console.error("Failed to submit selected category...", data);
      }
    } catch (err) {
      console.error("Failed to submit selected category...", err);
      throw err;
    }
  }

  const handleCreateAssessment = async () => {
    setIsLoading(true);
    try {
      await submitCategoryToApi(id);
      navigate("/explainer/" + id + "?" + searchParams, { replace: true });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const howItWorksLink = () => {
    if (searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    // return "/assessment?" + searchParams;
    navigate("/assessment?" + searchParams);

  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }

  const introPageLink = () => {
    // createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden;"
      videoElm.style.cssText = "width: 100%; margin: 0 auto;background-color: #004F88; border-radius: 0;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if (!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 50%; padding: 45px 2.5%; overflow: hidden;";
      videoElm.style.cssText = "width: 50%; margin: 0 auto; background-color: #0E435C; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 50%; padding: 45px 2.5%; overflow: hidden;";
      videoElm.style.cssText = "width: 50%; margin: 0 auto; background-color: #0E435C; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";

      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    showThumbnail();
    showPlayButton();
  }

  // let videoElement = document.getElementById('vid')
  // // let currentTime = 0;
  // let vidDuration = 0;
  // // let vid = document.getElementById('vid')?.duration
  // // let vidDuration = Math.floor(vid)
  // // let currentTime = Math.floor(time)

  // if(videoElement) {
  //   currentTime = Math.floor(videoElement.currentTime);
  //   vidDuration = Math.floor(videoElement.duration);

  //   if (isNaN(vidDuration)) {
  //     vidDuration = 0;
  // }
  // }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const video = vidRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(video.duration);
    };

    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, []);

  const handleSliderChange = (e) => {
    const video = vidRef.current;
    video.currentTime = e.target.value;
    setCurrentTime(video.currentTime);
  };


  const showThumbnail = () => {
    if (playingVideo) {
      return (
        <>
          <div className='custom-controls'>
            <button className='toggle-play-pause'><Pause onClick={(e) => pauseVideo(e)} /></button>
            <input type="range" className='custom-slider' id='timeRange' min='0' max={duration} value={currentTime} onChange={handleSliderChange} step="0.1" />
            <span> {formatTime(currentTime)}/ {formatTime(duration)}</span>
          </div>
          <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />
        </>
      )
    } else {
      if (startPlaying) {
        return null
      } else {
        return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if (playingVideo) {
      return null
    } else {
      return <img alt="play button" className={searchParams.get("leader") ? "playbtn leader" : "playbtn"} src={PlayButton} onMouseOver={e => (e.currentTarget.src = PlayButtonHover)} onMouseOut={e => (e.currentTarget.src = PlayButton)} onClick={(e) => playVideo(e)} />
    }
  }



  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-missio-nexus-intro.mp4";

  return (
    <div className="container start">
      <div className="video" ref={videoElmRef}>
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd} id='vid'>
          <source src={vidsrcURL} type="video/mp4" />
        </video>
        {showThumbnail()}
        {showPlayButton()}

        {playingVideo && <Close className="close-video" style={{
          position: "absolute", height: "20px", width: "20px", color: "#fefefebb", opacity: 0.75, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer",
          right: windowWidth > 1100 ? "70px" : "5px"
        }} onClick={(e) => pauseVideo(e)} />}
      </div>
      {/* { windowWidth < 550 ? 
        <div style={{ width: '100%', height: '12px', backgroundColor: '#0E435C'}}></div> : <div></div>
      } */}
      <div className="intro" ref={introElmRef}>
        {/* <h1>Do your church’s bookkeeping practices demonstrate solid financial discipline?</h1> */}
        <h1>{searchParams.get("leader") ? "Are you equipped well enough to accomplish your mission?" : "Are you and your team equipped well enough to accomplish your mission?"}</h1>
        <p style={{ fontWeight: "700", color: "#004F88" }}>Get a complete picture of your toolkit instantly!</p>
        {/* <p className='intro-para'>See how you compare* with similarly-sized American churches</p> */}
        {/* <div className="completion">See how you compare* with similarly-sized American churches</div> */}
        {/* <p>See how you compare with similarly-sized American churches</p> */}
        {/* <div className='intro-para'>
          <p>This survey seeks to provide you with a better understanding of your organization's information security posture.This survey touches on industry best practices, but is only a partial information security assessment.</p>
        </div> */}
        <ol className="intro-points-list">
          {searchParams.get("leader") ?
            <>
              <li>Review which tools you use today</li>
              <li>Compare your tools with those of similar ministries</li>
              <li>View your team’s toolkit on one dashboard</li>
              <li>Identify gaps to fill and tools to re-evaluate</li>
            </>
            :
            <>
              {/* <li>Review which tools you use today</li>
              <li>Invite your team to review as well</li>
              <li>View your team’s toolkit on one dashboard</li>
              <li>Identify gaps to fill and tools to re-evaluate</li> */}

              <li>Review which tools you use today</li>
              <li>Compare your tools with those of similar ministries</li>
              <li>Invite your team to share their toolkit details</li>
              <li>View your team’s toolkit on one dashboard</li>
              <li>Identify gaps to fill and tools to re-evaluate</li>

              {/* <li>Take this 5-min review</li>
              <li>Invite your team to take it after you</li>
              <li>Outline what your toolkit looks like</li>
              <li>View everyone’s input in one report</li>
              <li>Identify gaps to fill and tools to reevaluate</li> */}
            </>}
        </ol>

        <div className="completion">
          {/* <div className='btn-container'> */}
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          <Button className="cta" onClick={handleCreateAssessment}>
            {isLoading ? <CircularProgress size={20} style={{ color: '#ffffff' }} /> : 'Get Started'}
          </Button>

          {/* </div> */}
          <div className='time-estimate'>
            <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span style={{ margin: "auto" }}>5 mins</span>
          </div>
        </div>


        {/* <div className='para-container'>
          <p className='para'>Note: It is essential the individual most knowledgeable about your church’s financial practices takes this assessment. If you are not in such a position, please share this assessment with your accounting expert.</p>
          <p className='para'><b>Note:</b> Your progress and responses are saved automatically.</p>
        </div> */}
      </div>

    </div>
  );
}

export default Start;
