import { useState, useEffect, useContext } from 'react';
import Select, { components } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import { Button, Checkbox, FormGroup, Slider, LinearProgress, TextField, Radio, RadioGroup, FormControlLabel, InputAdornment, IconButton } from '@mui/material';
import AdditionalQuestionContext from '../../Store/AdditionalQuestionContext';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import textIcon from '../../Assets/Images/text-icon.png';
import { Clear } from '@mui/icons-material';


const Question = (props) => {

  console.log("Question props", props);

  const addCtx = useContext(AdditionalQuestionContext);
  const { windowWidth } = useWindowDimensions();
  const [showSlider, setShowSlider] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(90);
  const [maxWidth, setMaxWidth] = useState(props.question?.answers?.length);
  const [runTimeoutFunc, setRunTimeoutFunc] = useState(false);

  const [showOtherOptionTextBox, setShowOtherOptionTextBox] = useState(props?.question?.answerTxt?.length ? true : false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [textAns, setTextAns] = useState(props.question?.answerTxt);
  const [checkboxAnswers, setCheckboxAnswers] = useState([]);
  const [noneBtnClicked, setNoneBtnClicked] = useState(false);
  const [otherBtnClicked, setOtherBtnClicked] = useState(false);
  const [selectedBookkeepingSoftwareValue, setSelectedBookkeepingSoftwareValue] = useState({ label: "Select/start typing", value: "Select/start typing" });
  const [bookkeepingTextAns, setBookkeepingTextAns] = useState(props.question?.answerTxt);
  const [selectedSelectOptionValue, setSelectedSelectOptionValue] = useState({ label: "Select/start typing", value: "Select/start typing", orderNo: '' });

  // to map into select options
  useEffect(() => {

    const transformedOptions = props?.question?.answers?.map(answer => {
      console.log(answer);
      // if (answer.desktopText !== "Skip" && answer.desktopText !== "None") {
      if (answer.desktopText !== "Skip") {
        return {
          id: answer.id,
          label: answer.desktopText,
          value: answer.value,
          orderNo: answer?.orderNo || answer?.order_no
        };
      }
    }).filter(Boolean);

    console.log("props.question", textAns)
    // TODO: have to remove this after Bo delete option fixed
    const noneOption = transformedOptions?.find(option => option.label === "None");
    const otherOptions = transformedOptions?.filter(option => option.label !== "None");

    //to Place 'None' in the second position
    const finalOptions = [];
    if (otherOptions?.length > 0) {
      finalOptions.push(otherOptions[0]);  // First element
    }
    if (noneOption) {
      finalOptions.push(noneOption);  // 'None' as second element
    }


    if (otherOptions) {
      finalOptions.push(...otherOptions.slice(1).sort((a, b) => a.label.trim().localeCompare(b.label.trim())));
    }
    setSelectOptions(finalOptions);

    if ((props.question?.answerType === "select" || props.question?.answerType === "search_select" || props.question?.type === "select") && props?.question?.optionId) {
      const selectedOption = finalOptions?.find(item => item?.id === props?.question?.optionId);
      setSelectedSelectOptionValue(selectedOption);
    } else {
      setSelectedSelectOptionValue({ label: "Select/start typing", value: "Select/start typing", orderNo: '' });
    }
  }, [props?.question, props]);

  // empty text area on question change
  useEffect(() => {
    if (!props.question?.answerTxt || props.question?.answerTxt == "") {
      setTextAns("");
    } else {
      setTextAns(props.question?.answerTxt);
    }

  }, [props.question]);


  useEffect(() => {
    if (props.question?.answerTxt?.length > 0 && props.question?.answer === 2) {
      setShowOtherOptionTextBox(true)
    } else {
      setShowOtherOptionTextBox(false)
      // setSelectedBookkeepingSoftwareValue(props?.question?.isOtherValue);
    }
  }, [props.question]);

  // useEffect(() => {
  //   props?.updateIsOtherOption(props?.question?.id, showOtherOptionTextBox)
  // }, [showOtherOptionTextBox]);

  useEffect(() => {
    if (props.question?.answerType === "checkbox" || props.question?.type === "checkbox") {
      setCheckboxAnswers(props.question?.answers);
      // if (props.question?.answers[props.question?.answers?.length - 1]?.checked) setShowOtherOptionTextBox(true);
    } else setCheckboxAnswers([]);

  }, [props.question?.answerType, props.question?.answers, props.question]);

  useEffect(() => {
    if (runTimeoutFunc) {
      const timer = setTimeout(() => {
        if (props.question?.answer == 0 || !props.question.answer) {
          setShowSlider(false);
        }
      }, 500);
      return () => {
        setRunTimeoutFunc(false);
        clearTimeout(timer);
      }
    }

  }, [props.question?.answer, runTimeoutFunc]);

  // console.log("showSlider", showSlider);

  useEffect(() => {
    if ((props.question?.answer == 0 || !props.question?.answer) && !progress) {
      setShowSlider(false);
    }
    else if (props.question?.answer > 0 || progress > 0) {
      setShowSlider(true);
    }
  }, [props.question?.answer, progress])

  useEffect(() => {
    if (props.question?.answer == 0 || !props.question?.answer) setProgress(0);
    // setProgress(props.question.answer);
    else if (props.question?.answer > 0) {
      const findAnsOption = props.question?.answers?.find((ans) => ans?.value === props?.question?.answer);
      setProgress(findAnsOption?.orderNo || findAnsOption?.order_no);
    }
  }, [props.question]);

  useEffect(() => {
    switch (props.question?.answers?.length) {
      case 2: setSliderWidth(36); break;
      case 3: setSliderWidth(56); break;
      case 4: setSliderWidth(76); break;
      case 5: setSliderWidth(85); break;
      case 6: setSliderWidth(90); break;
      default: break;
    }

    setMaxWidth(props.question?.answers?.length);


  }, [props, props.question?.question, props.question?.answer]);

  // this is a custom update only for ACST Bookkeeping assessment
  // selection between None and Other for bookkeeping software dropdown
  // useEffect(() => {
  //   const ansTxt = props.question?.answerTxt;

  //   // if (ansTxt === "None") {
  //   //   setShowOtherOptionTextBox(false);
  //   //   setNoneBtnClicked(true);
  //   //   setOtherBtnClicked(false);
  //   // } else if (ansTxt !== "None" && ansTxt?.toString()?.trim()?.length) {
  //   //   setShowOtherOptionTextBox(true);
  //   //   setNoneBtnClicked(false);
  //   //   setOtherBtnClicked(true);
  //   // }

  //   if (ansTxt?.length) {
  //     let selected = addCtx?.bookkeepingSoftwareValues?.find(val => val.value === ansTxt);
  //     setSelectedBookkeepingSoftwareValue(selected);
  //     selected?.value && setBookkeepingTextAns(selected?.value);
  //   }

  // }, [props.question, props.question?.answerTxt]);


  // console.log("props.question.answer", props.question.answer);
  // console.log("progress", progress); console.log("showSlider", showSlider);

  useEffect(() => {
    // const ansTxt = props.question?.isOtherValue;

    // if (ansTxt === "None") {
    //   setShowOtherOptionTextBox(false);
    //   setNoneBtnClicked(true);
    //   setOtherBtnClicked(false);
    // } else if (ansTxt !== "None" && ansTxt?.toString()?.trim()?.length) {
    //   setShowOtherOptionTextBox(true);
    //   setNoneBtnClicked(false);
    //   setOtherBtnClicked(true);
    // }

    // if (ansTxt) {
    //   let selected = addCtx?.bookkeepingSoftwareValues?.find(val => val.value === ansTxt.value);
    //   setSelectedBookkeepingSoftwareValue(selected);
    //   selected && setBookkeepingTextAns(selected);
    // }

  }, [props.question]);


  const getLeft = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }

  const getLeftRadio = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 6;
      case 6: return index * 4;
      default: return index * 20;
    }
  }

  const getRadioWidth = (answers) => {

    switch (answers) {
      // case 3: return {width: "70%", marginLeft: "7.5%"};
      case 3: return { width: windowWidth > 1000 ? "50%" : windowWidth > 900 ? "50%" : windowWidth < 651 && windowWidth > 550 ? "90%" : windowWidth < 551 ? "95%" : "70%" };
      // case 4: return { width: "75%", margin: "0 auto" };

      case 4: return { width: windowWidth < 551 ? "95%" : windowWidth < 651 ? "90%" : windowWidth < 831 ? "80%" : "45ch", margin: "0 auto" };
      case 5: return { width: "90%", marginLeft: "2.5%" };
      // case 6: return { width: "100%", marginLeft: "0%" };
      case 6: return { width: "80%", margin: 'auto' };
      default: return { width: "50%" };
    }
    // if(answers === 3) { return {width: "70%", marginLeft: "7.5%"}; }
    // return {width: "50%"};
  }

  const radioOptionWidth = (answers) => {
    switch (answers) {
      case 3: return "90%";
      // case 4: return "90%";
      case 4: return "100%";
      case 5: return "20%";
      case 6: return "16%";
      default: return "20%";
    }
  }

  // console.log("progress", progress);

  // console.log("Q.No.", props?.question?.orderNo, "progress", progress);

  const updateStep = (ansVal, orderNo) => {
    console.log("\n\nansVal", ansVal);
    console.log("orderNo", orderNo, "\n\n");
    setShowSlider(true);
    // setTimeout(() => {setProgress(progressArg)}, 0);
    // props.questionAnswered(progressArg);
    setTimeout(() => { setProgress(orderNo) }, 0);
    // setTimeout(() => {setProgress(ansVal)}, 0);
    props.questionAnswered(ansVal);
    setRunTimeoutFunc(true);
  }

  const updateRadioStep = (ansVal, orderNo) => {

    setTimeout(() => { setProgress(orderNo) }, 0);
    props.questionAnswered(ansVal);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const updateMcqStep = (ansVal, orderNo) => {

    setTimeout(() => { setProgress(orderNo) }, 0);
    props.questionAnswered(ansVal);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const handleNone = () => {
    setTimeout(() => { setProgress(props?.question?.orderNo) }, 0);
    props.questionAnswered(0);
  }

  console.log("checkboxAnswers", checkboxAnswers);

  const getLabelStyle = () => {

    // console.log("props.question", props.question);
    if (window.innerWidth > 650) {

      if (props.question?.answers?.length === 5) {
        // return { width: "100%", marginLeft: "-50%" };
        return { width: "14ch", marginLeft: "-7ch" };
      } else if (props.question?.answers?.length === 4) {
        return { width: "95%" };
      } else if (props.question?.answers?.length === 3 && props.question?.answerType === "radio") {
        // return { width: "200%", marginLeft: "-100%" };
        // return { width: "25ch" };
      } else if (props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "auto", marginLeft: "0" };
      }
      // return { width: "14ch", marginLeft: "-7ch" };

    }
    else {

      if (props.question?.answers?.length === 6) {
        return { width: "9ch", marginLeft: "-4.5ch", wordBreak: "break-all" };
      }
      // if(props.question?.answers?.length === 5) {
      //   return { width: "120%", marginLeft: "-60%" };
      // } else if(props.question?.answers?.length === 4) {
      //   return { width: "150%", marginLeft: "-75%" };
      // } else if(props.question?.answers?.length === 3) {
      //   return { width: "300%", marginLeft: "-150%" };
      // } else if(props.question?.answers?.length === 2) {
      //   return { width: "500%", marginLeft: "-250%" };
      // } else {
      //   return { width: "150%", marginLeft: "-75%" };
      // }
      // else return { width: "12ch", marginLeft: "-6ch" };
      else if (props?.question?.answerType === "slider" || props?.question?.type === "slider") {
        // return { width: "9ch", marginLeft: "-4.5ch" };
        return { width: "12ch", marginLeft: "-6ch" };
      } else return { width: "auto" };
    }

  }

  const checkOpacity = (index) => {
    if (index === 0 && showSlider) return "0";
    else return "1";
  }

  const checkSelectedOption = (value) => {
    if (value) {
      return 'selected_label'
    }
    else {
      return ''
    }
  }

  const checkSelected = (optionOrderNo) => {
    // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if (progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      return "label active";
    }
    else return "label";
  }
  const backgroundColorSet = (optionOrderNo) => {
    // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if (progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      return "#F26722";
    }
    else return "#fff";
  }

  const updateSliderTextHighlight = (progress) => {
    console.log("progress", progress);
    console.log("desktopText", props?.question?.answers?.find(ans => ans.value === progress)?.desktopText);
    let activeSlider = null;
    // let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    switch (sliderText?.trim()) {
      case "Strongly Disgree":
        activeSlider = 1; break;
      case "Strongly<br/>Disgree":
        activeSlider = 1; break;
      case "Disagree":
        activeSlider = 2; break;
      case "Neutral":
        activeSlider = progress; break;
      case "Agree":
        activeSlider = 4; break;
      case "Strongly Agree":
        activeSlider = 5; break;
      case "Strongly<br/>Agree":
        activeSlider = 5; break;
      default:
        activeSlider = progress;
    }
    return activeSlider;
  }

  const updateQuestion = (event, step) => {
    // props.question.answerTxt = event.target.value;
    setTextAns(event.target.value);
    // setState({answerTxt: event.target.value});
    props.updateTextAnswer(event.target.value);
    // return true;
  }

  // const updateSelectAnswer =(event) => {
  //   setTimeout(() => {setProgress(event.orderNo)}, 0);
  //   props.questionAnswered(event.value);
  //   setSelectedSelectOptionValue(event)
  // }

  const updateSelectAnswer = (option) => {
    console.log("updateSelectAnswer event", option);
    setTimeout(() => { setProgress(option.orderNo) }, 0);
    // props.questionAnswered(event.value);
    if (option?.label?.toLowerCase()?.trim() === "other") {
      setShowOtherOptionTextBox(true);
    } else {
      props.questionAnswered(option);
    }
    setSelectedSelectOptionValue(option);
  }

  function updateTextAns(e) {
    const newText = e.target.value;
    setTextAns(newText);
    // answer also need to send  --> 2 for the other option 
    props.updateTextAnswer(props?.question?.id, newText, 2);
  }

  function handleClearTextAnswer(e) {
    setTextAns("");
    setShowOtherOptionTextBox(false);
  }

  const updateQuestionForQuestionTypeSelect = (event, step, textVal) => {
    const text = textVal.value?.toString()?.trim()?.length > 0 ? textVal.value : event.target.value;
    setBookkeepingTextAns(text);
    props?.updateTextAnswer(text);
    props?.updateOtherAnswerValue(props?.question.id, textVal)

  }

  // console.log("addCtx.questions", addCtx.questions);

  const handleMultiSelectionCheckboxes = (data, index, arr) => {

    // console.log("data", data); console.log("index", index); console.log("arr", arr);
    const optionId = data.id;
    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {
      if (item.id === optionId) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setCheckboxAnswers(updatedCheckboxAnswers);
    // props.updateCheckboxQuestion(data.question_id, updatedCheckboxAnswers);
    props.updateMultiSelectionCheckboxQuestion(data.question_id, updatedCheckboxAnswers);

    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }
  }

  const handleSingleSelectionCheckboxes = (data, index, arr) => {

    // console.log("data", data); console.log("index", index); console.log("arr", arr);

    const optionId = data.id;
    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {
      if (item.id === optionId) {
        return { ...item, checked: !item.checked };
      } else {
        return { ...item, checked: false };
      }
    });

    // console.log("updatedCheckboxAnswers", updatedCheckboxAnswers);

    const answer = updatedCheckboxAnswers?.find(ans => ans.checked)?.value;

    setCheckboxAnswers(updatedCheckboxAnswers);
    props.updateSingleSelectionCheckboxQuestion(data.question_id, answer, updatedCheckboxAnswers);

    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }
  }

  const handleCheckboxes = (clickedOption, index, arr) => {

    // console.log("clickedOption", clickedOption); console.log("index", index); console.log("arr", arr);
    const questionId = props.question.id;
    const clickedOptionId = clickedOption?.id;
    const clickedOptionText = clickedOption?.desktopText?.toLowerCase()?.trim();
    // find the "None of the above" (nota) option nota = none of the above
    const notaOption = checkboxAnswers?.find(item => item?.desktopText?.toLowerCase()?.trim() === "none of the above");
    // check if the current option is "None of the above"/nota
    const clickedNoneOfTheAbove = clickedOptionText?.includes("none of the above");

    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {

      if (clickedNoneOfTheAbove) {
        // if the clicked option is nota, toggle it
        if (item?.id === notaOption?.id) {
          return { ...item, checked: !item.checked };
        } else if (item?.checked) {
          // if nota is checked, uncheck all other options
          return { ...item, checked: false };
        } else return item;
      } else if (notaOption?.checked && item.id !== clickedOptionId) {
        // if nota is checked and the current option is not nota, uncheck it
        return { ...item, checked: false };
      } else if (item.id === clickedOptionId) {
        // toggle the checked state of the current option
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setCheckboxAnswers(updatedCheckboxAnswers);
    props.updateCheckboxQuestion(questionId, updatedCheckboxAnswers);

    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }

  }

  const handleQuestionTypeSelect = () => {

  }

  const handleNotApplicableQuestionSubmission = (e) => {
    const questionId = props.question.id;
    props.answerAsNotApplicable(questionId, e.target.checked);
  }

  const handleCreateNewDropdownOption = (inputValue) => {
    const newOption = { label: inputValue?.toString()?.trim(), value: inputValue?.toString()?.trim() };
    addCtx.setBookkeepingSoftwareValues((prev) => [...prev, newOption]);
    setSelectedBookkeepingSoftwareValue(newOption);
    setBookkeepingTextAns(newOption.value);
    props?.updateTextAnswer(newOption.value);
  };

  // for maxwidth
  function getQuestionsMaxWidth() {
    const questionId = props.question?.id;
    console.log(questionId, "Aman")
    const isMobile = window.innerWidth <= 768;
    let maxWidth = "60ch";
    switch (questionId) {
      case 1246: maxWidth = "55ch"; break;
      case 1639: maxWidth = "55ch"; break;
      case 1623: maxWidth = "65ch"; break;
      case 1642: maxWidth = "65ch"; break;
      case 1719: maxWidth = isMobile ? "35ch" : '60ch'; break;
      case 1594: maxWidth = isMobile ? "32ch" : "60ch"; break;
      case 1621: maxWidth = isMobile ? "34ch" : "60ch"; break;
      case 1622: maxWidth = isMobile ? "35ch" : "60ch"; break;
      case 1721: maxWidth = isMobile ? "26ch" : "55ch"; break;
      case 1625: maxWidth = isMobile ? "26ch" : "55ch"; break;
      case 1632: maxWidth = isMobile ? "24ch" : "55ch"; break;
      case 2225: maxWidth = isMobile ? "28ch" : "55ch"; break;
      case 1626: maxWidth = isMobile ? "29ch" : "60ch"; break;
      case 1616: maxWidth = isMobile ? "24ch" : "60ch"; break;

      case 1617:
      case 1613:
      case 1720:
      case 1620:
      case 1629:
      case 1636:
      case 1640:
        maxWidth = isMobile ? "30ch" : "60ch";
        break;

      case 1638:
        maxWidth = isMobile ? "25ch" : "60ch";
        break;

      case 1619:
        maxWidth = isMobile ? "28ch" : "60ch";
        break;

      default: maxWidth = "60ch"; break;
    }
    return maxWidth;
  }
  const questionModifier = (question) => {
    const questionId = props.question?.id;
    const isMobile = window.innerWidth <= 768;
    switch (questionId) {
      case 1616: return isMobile ? 'Which association/ denomination/network are you MOST plugged in with?' : question;
    }
    return question;
  }

  const customSelectStyle = {

    control: (provided, state) => ({
      ...provided,
      borderBottomLeftRadius: state.isFocused ? "10px" : "10px",
      borderBottomRightRadius: state.isFocused ? "10px" : "10px",
      borderTopRightRadius: state.isFocused ? "10px" : "10px",
      borderTopLeftRadius: state.isFocused ? "10px" : "10px",
      border: state.isFocused ? 'none' : provided.border,
      boxShadow: state.isFocused ? '0 0 0 3px #99BB00' : provided.boxShadow,
      zIndex: state.isFocused ? 2 : 'auto',
    }),

    menu: (provided, state) => ({
      ...provided,
      borderTopLeftRadius: state.isFocused ? 0 : 0,
      borderTopRightRadius: state.isFocused ? 0 : 0,
      borderBottomLeftRadius: state.isFocused ? "10px" : "10px",
      borderBottomRightRadius: state.isFocused ? "10px" : "10px",
      overflow: 'hidden',
      marginTop: 0,
      zIndex: 1,
    }),

    menuList: (provided) => ({
      ...provided,
      padding: 0,
      overflow: 'hidden',
    }),
  };

  return (
    <div>

      <div className="question" style={{ minHeight: props?.question?.answerType === "radio" ? "140px" : "auto", maxWidth: getQuestionsMaxWidth() }}>
        <p>{questionModifier(props.question?.question)}</p>
      </div>

      {
        (() => {
          let answerType = props.question?.answerType || props.question?.type;

          switch (answerType) {

            case "checkbox":
              return <>
                <div className="question-checkbox-container">
                  <FormGroup>
                    {/* {props.question?.answers?.map((data, index, arr) => ( */}
                    {checkboxAnswers?.map((data, index, arr) => (
                      <FormControlLabel key={`labels-${data?.id}`} className="checkbox-label"
                        onChange={() => handleCheckboxes(data, index, arr)} control={
                          <Checkbox checked={data.checked || false} />} label={data.desktopText || data.desktop_text}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            color: data.checked ? "#F26722" : "#343333", // Change colors based on checked state
                          },
                        }}
                      />
                    ))}
                  </FormGroup>
                  {
                    showOtherOptionTextBox
                      ? <TextField multiline rows={windowWidth < 651 ? 4 : 2} hiddenLabel placeholder="Please type your response here" value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ height: "auto" }} />
                      : null
                  }
                </div>
              </>

            case "textarea":
              return <>
                <div className="textarea-field">
                  {/* <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="Please type your response here" value={props.question?.answerTxt} onChange={(e) => {updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
                  <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="Please type your response here" value={textAns} onChange={(e) => { updateTextAns(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text.question-type-select" />
                </div>
              </>

            case "radio":
              return <>
                <div className="radio-container">
                  <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                    {
                      props.question?.answers?.map((data, index) => (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={checkSelected(data.orderNo || data.order_no)} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', /* width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer" }} onClick={() => {
                          updateRadioStep(data.value, data.orderNo || data.order_no);
                        }}>
                          {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                          {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                          <Radio checked={data.value === progress} value={progress} sx={{ '&, &.Mui-checked': { color: '#343333', }, }} />
                          <p className="desktop" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                          <p className="mobile" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                        </div>
                      ))
                    }
                    {/* { 
                          props.question?.answers.map((data, index) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                            // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                          ))
                        } */}

                  </div>
                </div>
              </>

            case "multiple_choice":
              return <>
                <div className="multiple-choice-container">
                  <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                    {
                      // props.question?.answers?.map((data, index) => (
                      //   // were using checkSelected(data.value) here before this change to orderNo
                      //   <div className={`button-${index} ${checkSelected(data.orderNo || data.order_no)}`} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', /* width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer", justifyContent: 'center', alignItems: 'center', width: '128px', height: '41px', borderRadius: '5px' }} onClick={() => {
                      //     updateMcqStep(data.value, data.orderNo || data.order_no);
                      //   }}>
                      //     <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                      //     <FormControlLabel value={data.value} control={<Checkbox/>} label={data.value} />
                      //     <p className="desktop" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                      //     <p className="mobile" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                      //   </div>
                      // ))
                    }
                    {
                      props.question?.answers.map((data, index) => (
                        <FormControlLabel value={data.value} control={<Checkbox />} label={data.desktopText} onClick={() => {
                          props.updateMultiSelectQuestion(data)
                        }} />
                        // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                      ))
                    }

                  </div>

                  {props.question.na === "yes" &&
                    <FormGroup row className="not-applicable-container">
                      <FormControlLabel
                        control={<Checkbox checked={props.question?.skip || false} onChange={handleNotApplicableQuestionSubmission}
                          style={{ color: '#343333' }}
                        />}
                        label="Not Applicable" />
                    </FormGroup>
                  }

                </div>
              </>
            // TODO: will fix with BO not updating right now
            case "select":
            case "search_select":
              return <>
                <div className="select-container">
                  <div className="select-btn-container">

                    {/* {!showOtherOptionTextBox && <Select classNamePrefix="react-select" className={`form-details-text react-select ${checkSelectedOption(props?.question?.optionId)}` }
                    options={selectOptions}
                    onChange={(e) => updateSelectAnswer(e)}
                    value={selectedSelectOptionValue}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#99BB00",
                        primary50: "#99BB00",
                        primary: "#99BB00",
                        neutral0: "#F3F3F3",
                        neutral50: "#99BB00", // placeholder text color
                      },
                    })}
                    styles={customSelectStyle}
                    placeholder={"Start typing or select"} />
                  } */}

                    {
                      !showOtherOptionTextBox && <Select
                        classNamePrefix="react-select"
                        className={`form-details-text react-select ${checkSelectedOption(props?.question?.optionId)}`}
                        options={selectOptions}
                        onChange={(e) => updateSelectAnswer(e)}
                        value={selectedSelectOptionValue}
                        // menuIsOpen
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#99BB00", // Light shade for hovered/focused option
                            primary50: "#99BB00", // Darker shade for selected option
                            primary: "#99BB00",   // Main color for selected option
                            neutral0: "#F3F3F3",  // Background color for the dropdown
                            neutral50: "#99BB00", // Placeholder text color
                          },
                        })}
                        styles={{
                          option: (styles, { isFocused, isSelected }) => ({
                            ...styles,
                            backgroundColor: isSelected ? "#99BB00" : undefined,
                            color: isSelected ? "#FFFFFF" : "#555555",
                            fontFamily: "Lato",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "21.6px",
                            letterSpacing: "0%",
                          }),
                          singleValue: (styles) => ({
                            ...styles,
                            color: "#99BB00", // Text color of the selected value
                          }),
                          placeholder: (styles) => ({
                            ...styles,
                            color: "#99BB00", // Placeholder text color
                          }),
                          control: (styles) => ({
                            ...styles,
                            backgroundColor: "#F3F3F3", // Control background color
                          }),
                        }}
                        placeholder={"Start typing or select"}
                        components={{
                          Input: (props) => <components.Input {...props} inputMode="none" readOnly />, // Disables keyboard
                        }}
                      />
                    }

                    {showOtherOptionTextBox && <div className='react-creatable-container'>
                      {props.showOtherOption && <p className="other-option-text">Other</p>}
                      <TextField hiddenLabel multiline
                        rows={1}
                        placeholder="Start Typing"

                        value={textAns} onChange={(e) => { updateTextAns(e) }}
                        fullWidth
                        variant="outlined"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            height: '45px',
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused': {
                              border: "3px solid #99BB00",
                              backgroundColor: "#F3F3F3",
                            },
                            '& .MuiInputBase-input': {
                              height: '100%',
                              boxSizing: 'border-box',
                              fontWeight: '700',
                              fontSize: "18px",
                              color: "#555555",
                              fontFamily: "Lato, sans-serif",

                            },
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: '400',
                              color: "rgba(52, 51, 51, 0.3)",
                              fontSize: "18px",
                              fontFamily: "Lato, sans-serif",
                            },
                          },
                        }}
                        size="normal"
                        className="question-type-select"
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={textIcon} height={20} alt="text icon" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClearTextAnswer}>
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* <input type="text" className="form-details-text question-type-select" value={textAns} onChange={(e) => {updateTextAns(e)}} /> */}
                      {/* <ReactSelectCreatable classNamePrefix="react-select" 
                    className="form-details-text react-select question-type-select" 
                    value={selectedBookkeepingSoftwareValue} 
                    // onChange={(e) => {updateQuestionForQuestionTypeSelect(e, 1)}} 
                    onChange={(newValue, e) => {
                      setSelectedBookkeepingSoftwareValue(newValue);
                      updateQuestionForQuestionTypeSelect(e, 1, newValue);
                    }}
                    options={addCtx.bookkeepingSoftwareValues} 
                    onCreateOption={handleCreateNewDropdownOption}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors, primary25: '#99BB00',
                        primary50: '#99BB00', primary: '#99BB00',
                        neutral0: '#F3F3F3', neutral50: '#868686', // placeholder text color
                      },
                    })}
                    placeholder={"Start typing"}
                  /> */}
                    </div>}

                    {/* <Button variant="contained"
                      className={`select-btn none-btn ${noneBtnClicked ? 'selected' : ''}`}
                      onClick={(e) => {
                        updateTextAns({
                          target: {
                            value: "None"
                          }
                        })
                        setNoneBtnClicked(true);
                        setShowOtherOptionTextBox(false);
                        handleNone()
                      }}>None</Button> */}
                    {/* <Button variant="contained" 
                    className={`select-btn other-btn ${showOtherOptionTextBox ? 'selected' : ''}`} 
                    onClick={(e) => {
                      setShowOtherOptionTextBox(true);
                      setBookkeepingTextAns("");
                      setSelectedBookkeepingSoftwareValue({ label: "Select/start typing", value: "Select/start typing" });

                    }}>Other</Button> */}
                  </div>


                </div>
              </>

            default:
              return <div className="slider-container" style={{ width: sliderWidth + '%' }}>
                <div className="slider">
                  {showSlider ? <Slider
                    key={props.question?.id}
                    valueLabelDisplay="off"
                    step={1}
                    min={1}
                    max={maxWidth}
                    value={progress}
                    // value={updateSliderTextHighlight(progress)}
                    className="custom-slider"
                  /> : <div className="slider-none-adjust"></div>}
                  <div className="slider-points">
                    {
                      props.question?.answers?.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value, data.orderNo || data.order_no)}></a>
                      ))
                    }
                  </div>
                  <LinearProgress className="custom-bar" variant="determinate" value={0} />

                </div>
                <div className="slider-labels">
                  {
                    props.question?.answers?.map((data, index) => {
                      return (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={checkSelected(data.orderNo || data.order_no)} key={`labels-${index}`} style={{ left: getLeft(index) + '%' }} onClick={() => updateStep(data.value, data.orderNo || data.order_no)}>
                          {/* <p className="desktop" style={getLabelStyle()}>{data.desktopText}</p> */}
                          <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.desktopText || data.desktop_text }} />
                          {/* <p className="mobile" style={getLabelStyle()}>{data.mobileText}</p> */}
                          <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.mobileText || data.mobile_text }} />
                        </div>
                      );
                    })}
                </div>
              </div>
          }
        }).call(this)
      }

      {/* <div className="slider-container" style={{ width: state.sliderWidth + '%' }}>
        <div className="slider">
          { state.showSlider ? <Slider
            key={props.question.id}
            valueLabelDisplay="off"
            step={1}
            min={1}
            max={state.maxWidth}
            value={state.progress}
            className="custom-slider"
          /> : <div className="slider-none-adjust"></div> }
          <div className="slider-points">
          {/* { !state.showSlider ? 
            <a className="point" style={{ left: '0%'}} onClick={() => updateStep(1)}></a> : null } */}
      {/* { 
              props.question?.answers.map((data, index) => (
                <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
              ))
            }
          </div>
          <LinearProgress className="custom-bar" variant="determinate" value={0} />
          
        </div>
        <div className="slider-labels">
          { 
            props.question?.answers.map((data, index) => (
              <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktopText }}></p>
                <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobileText }}></p>
              </div>
            ))
          }
        </div>
      </div> */}

    </div>
  );
}

export default Question;

